import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

const SchemaPodcastEpisode = ({
  url,
  title,
  published,
  modified,
  description,
  medialink,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org/",
                "@type": "PodcastEpisode",
                "url": "${url}",
                "name": "${title}",
                "datePublished": "${published}",
                "dateModified": "${modified}",
                "description": "${description}",
                "associatedMedia": {
                "@type": "MediaObject",
                "contentUrl": "${medialink}"
                },
                "partOfSeries": {
                "@type": "PodcastSeries",
                "name": "${data.site.siteMetadata.title}",
                "url": "${data.site.siteMetadata.siteUrl}"
            }
          }             
        `}
      </script>
    </Helmet>
  )
}

export default SchemaPodcastEpisode
