import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

function shuffleArray(array) {
  let i = array.length - 1
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const AdsBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      allCockpitWerbebanner {
        nodes {
          bild {
            value {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          link {
            value
          }
        }
      }
    }
  `)

  const shuffleData = shuffleArray(data.allCockpitWerbebanner.nodes)

  return (
    <div className="bg-gray-100 mx-auto" style={{ maxWidth: 310 }}>
      <div style={{ padding: 5 }}>
        <div className="text-xs">Werbung</div>
        {shuffleData.slice(0, 1).map(ad => {
          return (
            <a href={ad.link.value} target="_blank" rel="noopener noreferrer">
              <Img fluid={ad.bild.value.childImageSharp.fluid} />
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default AdsBanner
