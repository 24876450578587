import React from "react"
const H3 = ({ children, className }) => {
  return (
    <h3
      className={`text-lg font-extrabold leading-relaxed sm:text-xl mmd:text-2xl ${className}`}
    >
      {children}
    </h3>
  )
}

export default H3
