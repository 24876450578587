import React from "react"
import { Link } from "gatsby"
import SvgHome from "../commons/svgs/SvgHome"
import SvgCarretRight from "../commons/svgs/SvgCarretRight"

const Breadcrumb = ({ pages }) => {
  return (
    <div className="bg-black">
      <nav
        className="flex py-3 max-w-7xl mx-auto justify-center"
        aria-label="Breadcrumb"
      >
        <Link to="/">
          <SvgHome className="w-6 h-6 text-white hover:text-gray-500" />
        </Link>
        {pages.map((page, i) => {
          if (pages.length === i + 1) {
            return (
              <>
                <div className="hidden mmd:block">
                  <SvgCarretRight className="w-6 h-6 text-gray-200 ml-4" />
                </div>
                <div className="text-white hover:text-gray-500 font-medium ml-4 text-sm hidden mmd:block">
                  {page.page}
                </div>
              </>
            )
          } else {
            return (
              <>
                <SvgCarretRight className="w-6 h-6 text-gray-200 ml-4" />
                <Link
                  to={page.to}
                  className="text-white hover:text-gray-500 font-medium ml-4 text-sm"
                >
                  {page.page}
                </Link>
              </>
            )
          }
        })}
      </nav>
    </div>
  )
}

export default Breadcrumb
