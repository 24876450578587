import React from "react"
const H1 = ({ children, className }) => {
  return (
    <h1
      className={`text-2xl font-extrabold leading-relaxed sm:text-3xl mmd:text-4xl ${className}`}
    >
      {children}
    </h1>
  )
}

export default H1
