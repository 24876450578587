import React from "react"
import { Link } from "gatsby"

const Button = ({
  text,
  to,
  href,
  onClick,
  target,
  rel,
  submit,
  className,
}) => {
  const classes = `w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-black hover:bg-black700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-bg-black700 focus:ring-white transition-colors duration-300  ${
    className ? className : ""
  }`

  if (to) {
    return (
      <Link to={to} className={classes}>
        {text}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className={classes} target={target} rel={rel}>
        {text}
      </a>
    )
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={classes}>
        {text}
      </button>
    )
  }

  if (submit) {
    return (
      <button type="submit" className={classes}>
        {text}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={`bg-red-600 ${classes}`}>
      Missing props
    </button>
  )
}

export default Button
